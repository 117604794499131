import React, { useEffect } from 'react'
import { Text, Pressable, ScrollView, View } from 'react-native'
import { Vehicle } from '../../../../../api/adapters/vehicle/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { FormValues } from './type'
import { styles } from './styles'
import { TextInputField } from '../../../../../common/components/TextInputField'

export function VehicleDetailComponent({
  vehicle,
  onSubmit,
}: {
  vehicle?: Vehicle
  onSubmit: (data: FormValues) => void
}) {
  console.log('vehicle', vehicle)
  // React Hook Form setup
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: vehicle || {},
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  useEffect(() => {
    if (vehicle) reset(vehicle) // Set initial form values for update
  }, [vehicle])

  // Form submission handler
  const onFormSubmit = async (data: FormValues) => {
    onSubmit(data)
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View>
        <Text style={styles.title}>{vehicle ? 'Add' : 'Update'} Vehicle</Text>
      </View>
      {/* Manufacturer */}
      <TextInputField
        control={control}
        name='manufacturer'
        label='Manufacturer'
        errorMessage={errors.manufacturer?.message}
        defaultValue='BMW'
        editable={!vehicle} // Only editable if it's an Add operation
      />

      {/* Model */}
      <TextInputField
        control={control}
        name='model'
        label='Model'
        errorMessage={errors.model?.message}
        defaultValue='M3'
        editable={!vehicle} // Only editable if it's an Add operation
      />

      {/* Year */}
      <TextInputField
        control={control}
        name='year'
        label='Production Year'
        errorMessage={errors.year?.message}
        defaultValue='2023'
        editable={!vehicle} // Only editable if it's an Add operation
      />

      {/* VIN */}
      <TextInputField
        control={control}
        name='vin'
        label='Vehicle Identification Number (VIN)'
        errorMessage={errors.vin?.message}
        defaultValue='4Y1SL65848Z411439'
        editable={!vehicle} // Only editable if it's an Add operation
      />

      {/* License Plate */}
      <TextInputField
        control={control}
        name='licensePlate'
        label='License Plate'
        errorMessage={errors.licensePlate?.message}
        defaultValue='SHP1234'
      />

      {/* Color */}
      <TextInputField
        control={control}
        name='color'
        label='Color'
        errorMessage={errors.color?.message}
        defaultValue='black'
      />

      {/* Mileage */}
      <TextInputField
        control={control}
        name='mileage'
        label='Mileage'
        errorMessage={errors.mileage?.message}
        defaultValue='2000'
      />

      <Pressable
        style={styles.button}
        aria-disabled={isSubmitting}
        onPress={handleSubmit(onFormSubmit)}
      >
        <Text style={styles.buttonText}>Save</Text>
      </Pressable>
    </ScrollView>
  )
}
