import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { AppRouts, RootStackParamList } from '../../../types'
import { CompanyProfileScreen } from '../../../../screens/private/Company/CompanyProfile'
import { CompanyServicedVehiclesScreen } from '../../../../screens/private/Company/CompanyServicedVehicles'
import { CompanyEmployeesScreen } from '../../../../screens/private/Company/CompanyEmployees'

const Drawer = createDrawerNavigator<RootStackParamList>()

export function LoadCompanyRoutes() {
  return (
    <>
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Company Profile',
        }}
        name={AppRouts.CompanyProfile}
        component={CompanyProfileScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Company Serviced Vehicles',
        }}
        name={AppRouts.CompanyServicedVehicles}
        component={CompanyServicedVehiclesScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Company Employees',
        }}
        name={AppRouts.CompanyEmployees}
        component={CompanyEmployeesScreen}
      />
    </>
  )
}
