import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  input: {
    borderColor: '#ccc',
    borderWidth: 1,
    padding: 10,
    marginVertical: 10,
    width: '80%',
    textAlign: 'center',
    fontSize: 18,
    borderRadius: 5,
  },
  countdown: {
    fontSize: 18,
    marginVertical: 10,
  },
})
