export enum VerificationType {
  Email = 'email',
  Phone = 'phone',
}
export enum AppRouts {
  // Public Routes
  Home = 'Home',
  Register = 'Register',
  AccountActivation = 'AccountActivation',
  Login = 'Login',
  NotFound = 'NotFound',

  // Private Roues
  Drawer = 'Drawer',
  CreateProfile = 'CreateProfile',

  // Main
  CustomerProfile = 'CustomerProfile',
  CustomerVehicles = 'CustomerVehicles',
  CustomerVehicleDetails = 'CustomerVehicleDetails',
  CustomerOpenServiceRequests = 'CustomerOpenServiceRequests',
  CustomerServiceHistory = 'CustomerServiceHistory',
  Settings = 'Settings',

  // Company
  CompanyProfile = 'CompanyProfile',
  CompanyEmployees = 'CompanyEmployees',
  CompanyServicedVehicles = 'CompanyServicedVehicles',

  // Employee
  EmployeeProfile = 'EmployeeProfile',
  EmployeeServicedVehicles = 'EmployeeServicedVehicles',
}

export type AccountActivationStackParam = { userId: string }
export type CustomerVehicleDetailsStackParam = { vehicleId?: string }

export type RootStackParamList = {
  [AppRouts.Home]: undefined
  [AppRouts.Register]: undefined
  [AppRouts.AccountActivation]: AccountActivationStackParam
  [AppRouts.Login]: undefined
  [AppRouts.NotFound]: undefined

  [AppRouts.Drawer]: undefined

  [AppRouts.CreateProfile]: undefined

  [AppRouts.CustomerProfile]: undefined
  [AppRouts.CustomerVehicles]: undefined
  [AppRouts.CustomerVehicleDetails]: CustomerVehicleDetailsStackParam
  [AppRouts.CustomerOpenServiceRequests]: undefined
  [AppRouts.CustomerServiceHistory]: undefined
  [AppRouts.Settings]: undefined

  [AppRouts.CompanyProfile]: undefined
  [AppRouts.CompanyEmployees]: undefined
  [AppRouts.CompanyServicedVehicles]: undefined

  [AppRouts.EmployeeProfile]: undefined
  [AppRouts.EmployeeServicedVehicles]: undefined

  // ... Add more routes as needed
}
