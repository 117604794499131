import React from 'react'
import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { useAuth } from '../contexts/AuthContext'
import { DeepLinkProvider } from '../contexts/DeepLinkContext'
import { linking } from './linking'
import { DrawerNavigator } from './DrawerNavigator'

// const Stack = createNativeStackNavigator<RootStackParamList>()

export function AppNavigation() {
  const {
    isAuthenticated,
    user,
    isInitialUserLoadComplete,
    logout,
    switchProfile,
  } = useAuth()

  if (!isInitialUserLoadComplete) {
    return
  }

  return (
    <NavigationContainer linking={linking} theme={DefaultTheme}>
      <DeepLinkProvider>
        <DrawerNavigator
          isAuthenticated={isAuthenticated}
          user={user}
          logout={logout}
          switchProfile={switchProfile}
        />
      </DeepLinkProvider>
    </NavigationContainer>
  )
}
