import React, { createContext, useContext, useEffect, useState } from 'react'
import * as Linking from 'expo-linking'
import { AppRouts } from '../navigation/types'

interface DeepLinkContextProps {
  currentUrl: string | null
  setCurrentUrl: React.Dispatch<React.SetStateAction<string | null>>
}

const DeepLinkContext = createContext<DeepLinkContextProps | undefined>(
  undefined
)

export const useDeepLink = () => {
  const context = useContext(DeepLinkContext)
  if (!context) {
    throw new Error('useDeepLink must be used within a DeepLinkProvider')
  }
  return context
}

export const DeepLinkProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentUrl, setCurrentUrl] = useState<string | null>(null)

  useEffect(() => {
    // Handle initial URL
    Linking.getInitialURL().then((url) => {
      if (url) handleUrl(url)
    })

    // Listen for deep link changes
    const subscription = Linking.addEventListener(
      'url',
      (event: Linking.EventType) => {
        handleUrl(event.url)
      }
    )

    return () => {
      subscription.remove()
    }
  }, [])

  const handleUrl = (url: string) => {
    setCurrentUrl(url)
    // Here we decide how to handle the navigation logic
    const parsedUrl = Linking.parse(url)

    if (
      parsedUrl.path === AppRouts.AccountActivation &&
      parsedUrl.queryParams?.token
    ) {
      const verificationToken = parsedUrl.queryParams?.token as string
      console.warn(
        'Account verification via EMAIL sent token is not yet implemented',
        verificationToken
      )
    }
    // Add more deep link handling logic based on your app's structure
  }

  return (
    <DeepLinkContext.Provider value={{ currentUrl, setCurrentUrl }}>
      {children}
    </DeepLinkContext.Provider>
  )
}
