import React from 'react'
import { AppRouts, RootStackParamList } from '../../../types'
import { HomePageScreen } from '../../../../screens/public/Home'
import { RegisterScreen } from '../../../../screens/public/Registration'
import { LoginScreen } from '../../../../screens/public/Login'
import { AccountActivation } from '../../../../screens/public/AccountActivation'
import { createDrawerNavigator } from '@react-navigation/drawer'

const Drawer = createDrawerNavigator<RootStackParamList>()

export function PublicRoutes() {
  return (
    <>
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Home',
        }}
        name={AppRouts.Home}
        component={HomePageScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Register',
        }}
        name={AppRouts.Register}
        component={RegisterScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Login',
        }}
        name={AppRouts.Login}
        component={LoginScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Account Activation',
        }}
        name={AppRouts.AccountActivation}
        component={AccountActivation}
      />
    </>
  )
}
