import { StackNavigationProp } from '@react-navigation/stack'
import {
  AppRouts,
  RootStackParamList,
  AccountActivationStackParam,
  CustomerVehicleDetailsStackParam,
} from './types'
import { useNavigation } from '@react-navigation/native'

// Define the hook for navigation helpers
export function useNavigationService() {
  // Call the hook once and store navigation
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  return {
    navigateRegister: () => navigation.navigate(AppRouts.Register),
    navigateLogin: () => navigation.navigate(AppRouts.Login),
    navigateHome: () => navigation.navigate(AppRouts.Home),
    navigateAccountActivation: (params: AccountActivationStackParam) =>
      navigation.navigate(AppRouts.AccountActivation, params),

    // Private
    // Customer
    navigateCustomerProfile: () =>
      navigation.navigate(AppRouts.CustomerProfile),
    navigateCustomerVehicles: () =>
      navigation.navigate(AppRouts.CustomerVehicles),
    navigateCustomerVehicleDetails: (
      params: CustomerVehicleDetailsStackParam
    ) => navigation.navigate(AppRouts.CustomerVehicleDetails, params),
    navigateCustomerOpenServiceRequests: () =>
      navigation.navigate(AppRouts.CustomerOpenServiceRequests),
    navigateCustomerServiceHistory: () =>
      navigation.navigate(AppRouts.CustomerServiceHistory),

    // Company Owner
    navigateCompanyProfile: () => navigation.navigate(AppRouts.CompanyProfile),
    navigateCompanyEmployees: () =>
      navigation.navigate(AppRouts.CompanyEmployees),
    navigateCompanyServicedVehicles: () =>
      navigation.navigate(AppRouts.CompanyServicedVehicles),

    // Employee
    navigateEmployeeProfile: () =>
      navigation.navigate(AppRouts.EmployeeProfile),
    navigateEmployeeServicedVehicles: () =>
      navigation.navigate(AppRouts.EmployeeServicedVehicles),
  }
}
