import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react'
import { fetchCurrentUser, User } from '../api/adapters/fetchCurrentUser'
import { getStoredToken, removeStoredToken, saveToken } from './storage'
import { useSpinner } from './SpinnerContext'

interface AuthContextProps {
  user: User | null
  token: string | null
  isAuthenticated: boolean
  isInitialUserLoadComplete: boolean
  loadUserData: () => Promise<void>
  setToken: (token: string) => Promise<void>
  logout: () => Promise<void>
  switchProfile: () => void
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { startLoader, stopLoader } = useSpinner()
  const [isInitialUserLoadComplete, setIsInitialUserLoadComplete] =
    useState<boolean>(false)
  const [user, updateUser] = useState<User | null>(null)
  const [token, updateToken] = useState<string | null>(null)

  useEffect(() => {
    initialLoad()
  }, [])

  const initialLoad = async () => {
    const storedToken = await getStoredToken()

    if (!storedToken) {
      setIsInitialUserLoadComplete(true)
      return logout()
    }

    updateToken(storedToken)
  }

  useEffect(() => {
    if (!token) {
      return
    }

    // Have to use setTimeout with 0 to shift a tick.
    setTimeout(() => {
      loadUserData()
    })
  }, [token])

  const loadUserData = async () => {
    // Fetch current user Profile.
    startLoader()

    try {
      const user = await fetchCurrentUser()
      setUser(user)
    } finally {
      stopLoader()
      setTimeout(() => {
        setIsInitialUserLoadComplete(true)
      })
    }
  }

  const setToken = async (token: string) => {
    updateToken(token)
    await saveToken(token)
  }

  const setUser = (user: User) => {
    updateUser(user)
  }

  const logout = async () => {
    console.log(`[AuthProvider]: [logout]: token: ${token}, user: ${user}`)
    updateUser(null)
    updateToken(null)
    await removeStoredToken()
  }

  const switchProfile = () => {
    // TODO
    console.log(
      `[AuthProvider]: [switchProfile]: token: ${token}, user: ${user}`
    )
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        isAuthenticated: !!user,
        isInitialUserLoadComplete,
        logout,
        loadUserData,
        setToken,
        switchProfile,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
