import React from 'react'
import { View, Text, TextInput, Pressable } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { registerUser } from '../../../api/adapters/registerUser'
import { showAlert } from '../../../common/utils/alert'
import { useNavigationService } from '../../../navigation/Service'
import { FormValues } from './type'
import { schema } from './schema'
import { styles } from './styles'
import commonStyles from '../../../common/styles/commonStyles'
import { AxiosError } from 'axios'

export function RegisterScreen() {
  const navigation = useNavigationService()

  // React Hook Form setup
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  // Form submission handler
  const onSubmit = async (data: FormValues) => {
    try {
      const result = await registerUser(data.email, data.password, data.phone)
      showAlert('Success', 'Registration successful!')
      navigation.navigateAccountActivation({ userId: result.id })
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      console.error(error)

      // TODO show detailed error messages.
      const message = errorMessage.includes('email_1 dup key')
        ? 'Duplicate Email'
        : errorMessage.includes("Field validation for 'Password'")
        ? 'Invalid Password'
        : 'Internal Error, Please contact mechanicshopinc@gmail.com'

      showAlert('Error', message)
    }
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Register</Text>
      {/* Email Field */}
      <Controller
        control={control}
        name='email'
        defaultValue='armen0089@gmail.com'
        render={({ field: { onChange, value } }) => (
          <View style={styles.inputContainer}>
            <TextInput
              placeholder='Email'
              value={value}
              onChangeText={onChange}
              inputMode='email'
              style={styles.input}
              autoCapitalize='none'
            />
          </View>
        )}
      />
      {errors.email && <Text style={styles.error}>{errors.email.message}</Text>}
      {/* Password Field */}
      <Controller
        control={control}
        name='password'
        defaultValue='Adm!n123'
        render={({ field: { onChange, value } }) => (
          <View style={styles.inputContainer}>
            <TextInput
              placeholder='Password'
              value={value}
              onChangeText={onChange}
              secureTextEntry
              style={styles.input}
            />
          </View>
        )}
      />
      {errors.password && (
        <Text style={styles.error}>{errors.password.message}</Text>
      )}

      {/* Repeat Password Field */}
      <Controller
        control={control}
        name='repeatPassword'
        defaultValue='Adm!n123'
        render={({ field: { onChange, value } }) => (
          <View style={styles.inputContainer}>
            <TextInput
              placeholder='Repeat Password'
              value={value}
              onChangeText={onChange}
              secureTextEntry
              style={styles.input}
            />
          </View>
        )}
      />
      {errors.repeatPassword && (
        <Text style={styles.error}>{errors.repeatPassword.message}</Text>
      )}

      {/* Phone Number Field */}
      <Controller
        control={control}
        name='phone'
        defaultValue='+34657810684'
        render={({ field: { onChange, value } }) => (
          <View style={styles.inputContainer}>
            <TextInput
              placeholder='Phone Number'
              value={value}
              onChangeText={onChange}
              inputMode='tel'
              style={styles.input}
            />
          </View>
        )}
      />
      {errors.phone && <Text style={styles.error}>{errors.phone.message}</Text>}
      <Pressable
        onPress={handleSubmit(onSubmit)}
        aria-disabled={isSubmitting}
        style={commonStyles.defaultButton}
      >
        <Text style={commonStyles.buttonText}>Register</Text>
      </Pressable>
    </View>
  )
}
