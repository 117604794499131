import React, { useState, useEffect } from 'react'
import { View, Text, TextInput, Pressable } from 'react-native'
import { activateAccount } from '../../../api/adapters/activateAccount'
import { useRoute } from '@react-navigation/native'
import { RootStackParamList } from '../../../navigation/types'
import { showAlert } from '../../../common/utils/alert'
import { useNavigationService } from '../../../navigation/Service'
import { VERIFICATION_CODE_EXPIRATION_TIME } from './constants'
import { styles } from './styles'
import commonStyles from '../../../common/styles/commonStyles'

export function AccountActivation() {
  const navigation = useNavigationService()
  const route = useRoute()
  const [userId, setUserId] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [countdown, setCountdown] = useState<number>(
    VERIFICATION_CODE_EXPIRATION_TIME
  )
  //const [isCodeSent, setIsCodeSent] = useState<boolean>(true); // Assume code is sent initially

  useEffect(() => {
    if (route.params) {
      const userId = (route.params as RootStackParamList['AccountActivation'])
        .userId
      setUserId(userId)
    }
  }, [route.params])

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined

    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)
    }

    return () => {
      if (timer) clearInterval(timer)
    }
  }, [countdown])

  const formatCountdown = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`
  }

  // TODO implement
  // const resendCode = () => {
  //   // Logic to resend the code (e.g., API call)
  //   setCountdown(300); // Reset countdown to 5 minutes
  //   setIsCodeSent(true); // Optionally, handle resend logic feedback
  // };

  const onActivateAccount = async () => {
    if (countdown > 0 && code.length === 6) {
      console.log('Activating account with code:', code)

      // Logic to activate account
      try {
        const { success } = await activateAccount(userId, code)

        if (success) {
          showAlert('Success', 'Registration successful! Please Login.')
          console.log('Account successfully activated')

          navigation.navigateHome()
        } else {
          showAlert('Error', 'Wrong code, Please try again.')
          setCode('')
          console.error('Failed to Activate Account')
        }
      } catch {
        showAlert('Error', 'Invalid Code, Please try again.')
        setCode('')
        console.error('Failed to Activate Account')
      }
    }
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Activate Your Account</Text>

      <TextInput
        style={styles.input}
        value={code}
        onChangeText={setCode}
        maxLength={6}
        inputMode='numeric'
        placeholder='Enter 6-digit code'
      />

      <Text style={styles.countdown}>
        Time remaining: {formatCountdown(countdown)}
      </Text>

      {/* <Pressable
        onPress={resendCode}
        style={commonStyles.defaultButton}
      >
        <Text style={commonStyles.buttonText}>Resend Code</Text>
      </Pressable> */}

      <Pressable
        onPress={onActivateAccount}
        aria-disabled={countdown === 0 || code.length !== 8 || !userId}
        style={commonStyles.defaultButton}
      >
        <Text style={commonStyles.buttonText}>Activate</Text>
      </Pressable>
    </View>
  )
}
