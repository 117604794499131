import * as yup from 'yup'

// Define the form schema using Yup
export const schema = yup.object().shape({
  manufacturer: yup.string().required('Manufacturer is required'),
  model: yup.string().required('Model is required'),
  year: yup
    .number()
    .min(1850)
    .max(new Date().getFullYear())
    .required('Year is required'),
  vin: yup.string().required('VIN is required'),
  licensePlate: yup.string().required('License Plate is required'),
  color: yup.string().required('Color is required'),
  mileage: yup.number().min(0).required('Mileage is required'),
})
