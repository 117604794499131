import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { AppRouts, RootStackParamList } from '../../../types'
import CreateProfileScreen from '../../../../screens/private/ProfileRegistration'

const Drawer = createDrawerNavigator<RootStackParamList>()

export function ProfileRegistrationRoute() {
  return (
    <Drawer.Screen
      options={{
        headerShown: true,
        title: 'Create Profile',
      }}
      name={AppRouts.CreateProfile}
      component={CreateProfileScreen}
    />
  )
}
