import React from 'react'
import { View, Text } from 'react-native'
import commonStyles from '../../../../common/styles/commonStyles'

export function CompanyProfileScreen() {
  return (
    <View style={commonStyles.container}>
      <Text style={commonStyles.text}>Welcome Business Profile Screen</Text>
    </View>
  )
}
