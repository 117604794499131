import React, { useEffect } from 'react'
import { AuthProvider, useAuth } from './contexts/AuthContext'
import { SpinnerProvider } from './contexts/SpinnerContext'
import { setupInterceptors } from './api/interceptors'
import { AppNavigation } from './navigation'
import { Loader } from './common/components/Loader'

const App = () => {
  const { token, logout } = useAuth() // Get the token from context

  useEffect(() => {
    // Set up the interceptor with the current token
    if (token) {
      setupInterceptors(token, logout)
    }
  }, [token])

  return <AppNavigation />
}

export default function AppWithProviders() {
  return (
    <SpinnerProvider>
      <AuthProvider>
        <App />
        <Loader />
      </AuthProvider>
    </SpinnerProvider>
  )
}
