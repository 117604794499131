import { apiClient } from '../../apiClient'
import { Vehicle } from './types'

export const addVehicle = async (
  customerId: string,
  payload: Vehicle
): Promise<void> => {
  return apiClient.post('/vehicles', {
    ...payload,
    customerId,
  })
}
