import React from 'react'
import { View, Text, TextInput, Pressable } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { showAlert } from '../../../common/utils/alert'
import { authenticate } from '../../../api/adapters/authenticate'
import { useAuth } from '../../../contexts/AuthContext'
import { FormValues } from './type'
import { schema } from './schema'
import { styles } from './styles'
import commonStyles from '../../../common/styles/commonStyles'
import { AxiosError } from 'axios'

export function LoginScreen() {
  const { setToken } = useAuth()

  // React Hook Form setup
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  const onSubmit = async ({ email, password }: FormValues) => {
    try {
      // Get oAuth token
      const { token } = await authenticate(email, password)
      setToken(token) // This will trigger 'fetchCurrentUser' in AuthContext
    } catch (error) {
      const data = (error as AxiosError).response?.data
      console.error(data)
      showAlert('Error', (data as { error: string }).error)
    }
  }

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Login</Text>

      {/* Email Input Field */}
      <Controller
        control={control}
        name='email'
        defaultValue='armen0089@gmail.com'
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.inputContainer}>
            <TextInput
              style={styles.input}
              placeholder='Email'
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              inputMode='email'
              autoCapitalize='none'
            />
            {errors.email && (
              <Text style={styles.error}>{errors.email.message}</Text>
            )}
          </View>
        )}
      />

      {/* Password Input Field */}
      <Controller
        control={control}
        name='password'
        defaultValue='Adm!n123'
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.inputContainer}>
            <TextInput
              style={styles.input}
              placeholder='Password'
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              secureTextEntry
            />
            {errors.password && (
              <Text style={styles.error}>{errors.password.message}</Text>
            )}
          </View>
        )}
      />

      {/* Submit Button */}
      <Pressable
        onPress={handleSubmit(onSubmit)}
        style={commonStyles.defaultButton}
      >
        <Text style={commonStyles.buttonText}>
          {isSubmitting ? 'Logging...' : 'Login'}
        </Text>
      </Pressable>
    </View>
  )
}
