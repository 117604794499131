import React from 'react'
import { ActivityIndicator, View, StyleSheet } from 'react-native'
import { useSpinner } from '../../contexts/SpinnerContext'

export const Loader = () => {
  const { loading } = useSpinner()

  if (!loading) return null

  return (
    <View style={styles.loaderContainer}>
      <ActivityIndicator size='large' color='#0000ff' />
    </View>
  )
}

const styles = StyleSheet.create({
  loaderContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})