import React, { useEffect, useState } from 'react'
import { View, Text, Pressable } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import { styles } from './styles'
import { FormValues } from './type'
import { TextInputField } from '../../../common/components/TextInputField'
import { UserScope } from '../../../common/constants/user'
import { Picker } from '@react-native-picker/picker'
import {
  CompanySummary,
  fetchCompanies,
} from '../../../api/adapters/fetchCompanies'
import {
  registerProfile,
  RegisterProfilePayload,
} from '../../../api/adapters/registerProfile'
import { useAuth } from '../../../contexts/AuthContext'
import { showAlert } from '../../../common/utils/alert'
import { AxiosError } from 'axios'

const CreateProfileScreen = () => {
  const { user, loadUserData } = useAuth()
  const [companies, setCompanies] = useState<CompanySummary[]>([])
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  const profileType = watch('type')

  // Fetch companies when screen loads
  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const fetchedCompanies = await fetchCompanies()
        console.log('[fetchedCompanies]: ', fetchedCompanies)

        setCompanies(fetchedCompanies)
      } catch (err) {
        // TODO handle properly
        console.error('Something went wrong when fetching companies', err)
      }
    }
    loadCompanies()
  }, [])

  const onSubmit = async (data: FormValues) => {
    const payload: RegisterProfilePayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: new Date(data.dateOfBirth),
      address: data.address,
      type: data.type,
    }

    if (data.type === UserScope.CompanyOwner) {
      payload.companyData = {
        name: data.companyName!,
        address: data.companyAddress!,
        businessRegistrationNumber: data.businessRegistrationNumber!,
      }
    } else if (data.type === UserScope.Employee) {
      console.log(data)
      payload.employeeData = {
        companyId: data.companyId!,
      }
    }

    try {
      await registerProfile(user!.userId, payload)
      showAlert('Success', 'Profile Registration successful!')
      await loadUserData()
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      console.error(error)

      // TODO show detailed error messages.
      const message = errorMessage.includes(
        'businessRegistrationNumber_1 dup key'
      )
        ? 'Duplicate Business Registration Number'
        : errorMessage.includes('name_1 dup key')
        ? 'Duplicate Company Name'
        : 'Internal Error, Please contact mechanicshopinc@gmail.com'

      showAlert('Error', message)
    }
  }

  return (
    <View style={styles.container}>
      {/* First Name */}
      <TextInputField
        control={control}
        name='firstName'
        label='First Name'
        errorMessage={errors.firstName?.message}
        defaultValue='User First Name'
      />

      {/* Last Name */}
      <TextInputField
        control={control}
        name='lastName'
        label='Last Name'
        errorMessage={errors.lastName?.message}
        defaultValue='User Last Name'
      />

      {/* Date of Birth */}
      <TextInputField
        control={control}
        name='dateOfBirth'
        label='Date of Birth (DD-MM-YYYY)*'
        errorMessage={errors.dateOfBirth?.message}
        defaultValue='12-12-2002'
      />

      {/* Address */}
      <TextInputField
        control={control}
        name='address'
        label='Address'
        errorMessage={errors.address?.message}
        defaultValue='street number, City, Zip code, Country'
      />

      {/* Type Dropdown */}
      <View style={styles.dropdown}>
        <Controller
          control={control}
          name='type'
          render={({ field: { onChange, value } }) => (
            <Picker selectedValue={value} onValueChange={onChange}>
              <Picker.Item label='Select Profile Type' value='' />
              <Picker.Item label='Customer' value={UserScope.Customer} />
              <Picker.Item
                label='Company Owner'
                value={UserScope.CompanyOwner}
              />
              <Picker.Item label='Employee' value={UserScope.Employee} />
            </Picker>
          )}
        />
        {errors.type && (
          <Text style={styles.errorText}>{errors.type.message}</Text>
        )}
      </View>

      {/* Conditional Fields */}
      {profileType === UserScope.Employee && (
        <View style={styles.dropdown}>
          <Controller
            control={control}
            name='companyId'
            render={({ field: { onChange, value } }) => (
              <Picker selectedValue={value} onValueChange={onChange}>
                <Picker.Item label='Select Company' value='' />
                {companies.map((company) => (
                  <Picker.Item
                    key={company.id}
                    label={company.name}
                    value={company.id}
                  />
                ))}
              </Picker>
            )}
          />
          {errors.companyId && (
            <Text style={styles.errorText}>{errors.companyId.message}</Text>
          )}
        </View>
      )}

      {profileType === UserScope.CompanyOwner && (
        <>
          <TextInputField
            control={control}
            name='companyName'
            label='Company Name'
            errorMessage={errors.companyName?.message}
            defaultValue='Company name 1'
          />
          <TextInputField
            control={control}
            name='companyAddress'
            label='Company Address'
            errorMessage={errors.companyAddress?.message}
            defaultValue='company address'
          />
          <TextInputField
            control={control}
            name='businessRegistrationNumber'
            label='Business Registration Number'
            errorMessage={errors.businessRegistrationNumber?.message}
            defaultValue='112233'
          />
        </>
      )}

      <Pressable style={styles.button} onPress={handleSubmit(onSubmit)}>
        <Text style={styles.buttonText}>Register</Text>
      </Pressable>
    </View>
  )
}

export default CreateProfileScreen
