import { apiClient } from '../apiClient'

export type RegisterProfilePayload = {
  firstName: string
  lastName: string
  dateOfBirth: Date
  address: string
  type: string
  companyData?: {
    name: string
    address: string
    businessRegistrationNumber: string
  }
  employeeData?: {
    companyId: string
  }
}

export const registerProfile = async (
  userId: string,
  data: RegisterProfilePayload
): Promise<void> => {
  await apiClient.post(`/profiles`, {
    userId,
    ...data,
  })
}
