import React from 'react'
import { View, Text } from 'react-native'
import commonStyles from '../../../common/styles/commonStyles'

export function NotFoundScreen() {
  return (
    <View style={commonStyles.container}>
      <Text style={commonStyles.text}>404 Page Not Found!!!</Text>
    </View>
  )
}
