import { StyleSheet } from 'react-native'

// Styling for the component
export const styles = StyleSheet.create({
  section: {
    marginBottom: 15,
  },
  divider: {
    height: 1,
    backgroundColor: '#ccc',
    marginHorizontal: 10,
    marginVertical: 5,
  },
})
