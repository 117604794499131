import * as yup from 'yup';

// TODO MOVE TO COMMON PLACE to be used by many components
// Define the form schema using Yup
export const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});