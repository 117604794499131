import * as yup from 'yup'
import { UserScope } from '../../../common/constants/user'

// Define the form schema using Yup
export const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  dateOfBirth: yup
    .string()
    .required('Date Of Birth is required')
    .matches(/^(\d{2})-(\d{2})-(\d{4})$/, 'Date must be in format DD-MM-YYYY')
    .test('is-date', 'Date Of Birth must be a valid date', (value) => {
      if (!value) return false
      const [day, month, year] = value.split('-').map(Number)
      const date = new Date(year, month - 1, day)
      return (
        date.getDate() === day &&
        date.getMonth() === month - 1 &&
        date.getFullYear() === year
      )
    })
    .test(
      'isWithinAgeRange',
      'Age must be between 18 and 99 years',
      (value) => {
        if (!value) return false
        const [day, month, year] = value.split('-').map(Number)
        const birthDate = new Date(year, month - 1, day)
        const today = new Date()
        const age = today.getFullYear() - birthDate.getFullYear()
        const monthDiff = today.getMonth() - birthDate.getMonth()
        const dayDiff = today.getDate() - birthDate.getDate()

        // Adjust age based on month and day differences
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
          return age - 1 >= 18 && age - 1 <= 99
        }
        return age >= 18 && age <= 99
      }
    ),
  address: yup.string().required('Address is required'),
  type: yup
    .mixed<UserScope>()
    .oneOf(Object.values(UserScope), 'Profile type is required')
    .required('Profile type is required'),

  // Conditionally required fields based on the `type` selected
  companyId: yup.string().when('type', {
    is: UserScope.Employee,
    then: (schema) => schema.required('Company is required for employees'),
    otherwise: (schema) => schema.notRequired(),
  }),
  companyName: yup.string().when('type', {
    is: UserScope.CompanyOwner,
    then: (schema) =>
      schema.required('Company Name is required for company owners'),
    otherwise: (schema) => schema.notRequired(),
  }),
  companyAddress: yup.string().when('type', {
    is: UserScope.CompanyOwner,
    then: (schema) => schema.required('Company Address is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  businessRegistrationNumber: yup.string().when('type', {
    is: UserScope.CompanyOwner,
    then: (schema) =>
      schema.required('Business Registration Number is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
})
