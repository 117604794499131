import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { DrawerNavigationPanel } from './NavigationPanel'
import { DrawerNavigatorProps } from './types'
import { AppRouts, RootStackParamList } from '../types'
import { SettingsScreen } from '../../screens/private/Settings'
import { PublicRoutes } from './Routes/PublicRoutes'
import { PrivateRoutes } from './Routes/PrivateRoutes'

const Drawer = createDrawerNavigator<RootStackParamList>()

export const DrawerNavigator = ({
  isAuthenticated,
  user,
  logout,
  switchProfile,
}: DrawerNavigatorProps) => {
  return (
    <Drawer.Navigator
      initialRouteName={AppRouts.Home}
      drawerContent={(props) => (
        <DrawerNavigationPanel
          {...props}
          isAuthenticated={isAuthenticated}
          user={user}
          logout={logout}
          switchProfile={switchProfile}
        />
      )}
      screenOptions={{
        drawerPosition: 'left', // Drawer on the left side
      }}
    >
      {isAuthenticated ? (
        <>
          {PrivateRoutes(user!.currentScope!)}
          <Drawer.Screen name={AppRouts.Settings} component={SettingsScreen} />
        </>
      ) : (
        PublicRoutes()
      )}
    </Drawer.Navigator>
  )
}
