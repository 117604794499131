import { apiClient } from '../../apiClient'
import { EmployeeStatus } from './types'

export const updateEmployeeStatus = async (
  employeeProfileId: string,
  status: EmployeeStatus
): Promise<{ message: string }> => {
  const resp = await apiClient.patch(`employees/${employeeProfileId}/status`, {
    status,
  })

  return { message: resp.data.message }
}
