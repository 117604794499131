import React, { useState } from 'react'
import {
  View,
  Text,
  // ScrollView,
  // TouchableOpacity,
  Pressable,
  FlatList,
  TouchableOpacity,
} from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'
import { Vehicle } from '../../../../api/adapters/vehicle/types'
import { fetchVehicles } from '../../../../api/adapters/vehicle/fetchVehicles'
import { useAuth } from '../../../../contexts/AuthContext'
import { styles } from './styles'
import { useNavigationService } from '../../../../navigation/Service'
import { useFocusEffect } from '@react-navigation/native'

export function CustomerVehiclesScreen() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const { user } = useAuth()
  const navigation = useNavigationService()

  //Fetch Vehicles list when screen loads
  // useEffect(() => {
  //   loadVehicles()
  // }, [])

  useFocusEffect(
    React.useCallback(() => {
      loadVehicles()
    }, [])
  )

  // const handleStatusUpdate = async () => {
  //   // try {
  //   //   await updateEmployeeStatus(id, status)
  //   //   showAlert('Success', 'Status successfully Updated!')
  //   //   loadEmployees()
  //   // } catch (error) {
  //   //   const errorMessage = (
  //   //     (error as AxiosError).response!.data as { error: string }
  //   //   ).error
  //   //   console.error(error)
  //   //   showAlert('Error', errorMessage)
  //   // }
  // }

  const loadVehicles = async () => {
    try {
      const data = await fetchVehicles(user!.userId!)
      setVehicles(data)
    } catch (err) {
      // TODO handle properly
      console.error('Something went wrong when fetching vehicles data', err)
    }
  }

  const renderVehicle = ({ item }: { item: Vehicle }) => (
    <View style={styles.vehicleRow}>
      <Text>
        {item.manufacturer} {item.model} ({item.year})
      </Text>
      <View style={styles.actions}>
        <TouchableOpacity
          onPress={() =>
            navigation.navigateCustomerVehicleDetails({ vehicleId: item.id })
          }
        >
          <MaterialIcons name='edit' size={24} color='blue' />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => console.log('Delete', item.id)}>
          <MaterialIcons name='delete' size={24} color='red' />
        </TouchableOpacity>
      </View>
    </View>
  )

  return (
    <View style={styles.container}>
      {vehicles.length ? (
        // <Text style={styles.section}>
        <View style={styles.section}>
          <FlatList
            data={vehicles}
            renderItem={renderVehicle}
            keyExtractor={(item) => item.id}
          />
        </View>
      ) : (
        // </Text>
        <View style={styles.centered}>
          <Text style={styles.noProfileText}>No Vehicles Available</Text>
        </View>
      )}

      <View style={styles.centered}>
        <Pressable
          style={styles.button}
          onPress={() => navigation.navigateCustomerVehicleDetails({})}
        >
          <Text style={styles.buttonText}>Add Vehicle</Text>
        </Pressable>
      </View>
    </View>
  )

  // return (
  //   <View style={styles.container}>
  //     <Pressable
  //       style={styles.button}
  //       onPress={() => navigation.navigateCustomerVehicleDetails({})}
  //     >
  //       <Text style={styles.buttonText}>Add Vehicle</Text>
  //     </Pressable>
  //     <FlatList
  //       data={vehicles}
  //       renderItem={renderVehicle}
  //       keyExtractor={(item) => item.id}
  //     />
  //   </View>
  // )

  // return (
  //   <ScrollView contentContainerStyle={styles.container}>
  //     {vehicles.map((vehicle: Vehicle) => (
  //       <View style={styles.row} key={vehicle.id}>
  //         <Text style={styles.column}>{vehicle.licensePlate}</Text>
  //         <Text style={styles.column}>{vehicle.manufacturer}</Text>
  //         <Text style={styles.column}>{vehicle.mileage}</Text>
  //         <Text style={styles.column}>{vehicle.model}</Text>
  //         <Text style={styles.column}>{vehicle.color}</Text>
  //         <View style={styles.actionColumn}>
  //           <>
  //             <TouchableOpacity
  //               onPress={() =>
  //                 handleStatusUpdate(employee.id, EmployeeStatus.Active)
  //               }
  //               style={styles.iconButton}
  //             >
  //               <MaterialIcons name='check-edit' size={24} color='green' />
  //             </TouchableOpacity>
  //             <TouchableOpacity
  //               onPress={() =>
  //                 handleStatusUpdate(employee.id, EmployeeStatus.Rejected)
  //               }
  //               style={styles.iconButton}
  //             >
  //               <MaterialIcons name='cancel' size={24} color='red' />
  //             </TouchableOpacity>
  //           </>
  //         </View>
  //       </View>
  //     ))}
  //   </ScrollView>
  // )
}
