import React, { useEffect, useState } from 'react'
import { useRoute } from '@react-navigation/native'
import { AppRouts, RootStackParamList } from '../../../../navigation/types'
import { Vehicle } from '../../../../api/adapters/vehicle/types'
import { fetchVehicle } from '../../../../api/adapters/vehicle/fetchVehicle'
import { useSpinner } from '../../../../contexts/SpinnerContext'
import { VehicleDetailComponent } from './components'
import { showAlert } from '../../../../common/utils/alert'
import { useNavigationService } from '../../../../navigation/Service'
import { FormValues } from './components/type'
import { addVehicle } from '../../../../api/adapters/vehicle/addVehicle'
import { AxiosError } from 'axios'
import { useAuth } from '../../../../contexts/AuthContext'
import { updateVehicle } from '../../../../api/adapters/vehicle/updateVehicle'

export function CustomerVehicleDetailsScreen() {
  const { user } = useAuth()
  const navigation = useNavigationService()
  const route = useRoute()
  const { startLoader, stopLoader } = useSpinner()
  const [readyToRender, setReadyToRender] = useState<boolean>(false)
  const [vehicle, setVehicle] = useState<Vehicle>()

  useEffect(() => {
    const vehicleId = (
      route.params as RootStackParamList[AppRouts.CustomerVehicleDetails]
    )?.vehicleId

    if (!vehicleId) return setReadyToRender(true)

    loadVehicle(vehicleId)
  }, [route.params])

  const loadVehicle = async (vehicleId: string) => {
    startLoader()

    try {
      const vehicle = await fetchVehicle(vehicleId)

      setVehicle(vehicle)
    } catch (error) {
      console.error('Something went wrong while fetching vehicle data', error)
      // const errorMessage = (
      //   (error as AxiosError).response!.data as { error: string }
      // )?.error
      //console.error(errorMessage)
      setVehicle(undefined)
    } finally {
      stopLoader()
      setReadyToRender(true)
    }
  }

  const onSubmit = async (data: FormValues) => {
    try {
      if (vehicle) {
        await updateVehicle(vehicle.id, {
          licensePlate: data.licensePlate,
          color: data.color,
          mileage: data.mileage,
        } as Vehicle)
      } else {
        await addVehicle(user!.userId, data as Vehicle)
      }

      showAlert('Success', 'Operation successful!')
      navigation.navigateCustomerVehicles()
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      console.error(error, errorMessage)

      // // TODO show detailed error messages.
      // const message = errorMessage.includes('email_1 dup key')
      //   ? 'Duplicate Email'
      //   : errorMessage.includes("Field validation for 'Password'")
      //   ? 'Invalid Password'
      //   : 'Internal Error, Please contact mechanicshopinc@gmail.com'

      //showAlert('Error', errorMessage)
    }
  }

  if (!readyToRender) return

  return <VehicleDetailComponent vehicle={vehicle} onSubmit={onSubmit} />
}
