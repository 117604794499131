import { apiClient } from '../../apiClient'

export type CompanySummary = {
  id: string
  name: string
  ownerId: string
  businessRegistrationNumber: string
  createdAt: Date
  updatedAt: Date
}

export const fetchCompany = async (
  companyId: string
): Promise<CompanySummary> => {
  const response = await apiClient.get(`/companies/${companyId}/summary`)

  return response.data as CompanySummary
}
