import React from 'react'
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer'

import { View } from 'react-native'
import { DrawerItemWrapperProps, DrawerNavigationPanelProps } from './types'
import { styles } from './styles'
import { useNavigationService } from '../../Service'
import { UserScope } from '../../../common/constants/user'
import { useNavigationState } from '@react-navigation/native'
import { AppRouts } from '../../types'

export const DrawerItemWrapper = ({
  label,
  onPress,
  focused,
}: DrawerItemWrapperProps) => (
  <DrawerItem
    label={label}
    onPress={onPress}
    focused={focused}
    activeTintColor='#3498db'
    activeBackgroundColor='#eaf6ff'
  />
)

export const DrawerNavigationPanel = ({
  isAuthenticated,
  user,
  logout,
}: DrawerNavigationPanelProps) => {
  const navigation = useNavigationService()

  const currentRoute = useNavigationState(
    (state) => state?.routes[state.index || 0]?.name
  )

  if (!isAuthenticated) {
    return (
      <DrawerContentScrollView>
        {/* Settings Section */}
        <View style={styles.section}>
          {/* <DrawerItem label='Settings' onPress={switchProfile} /> */}
          <DrawerItemWrapper
            label='Home'
            onPress={navigation.navigateHome}
            focused={currentRoute === AppRouts.Home}
          />
          <DrawerItemWrapper
            label='Login'
            onPress={navigation.navigateLogin}
            focused={currentRoute === AppRouts.Login}
          />
        </View>
      </DrawerContentScrollView>
    )
  }

  return (
    <DrawerContentScrollView>
      {/* Profiles Section */}
      <View style={styles.section}>
        <DrawerItemWrapper
          label='My Profiles'
          onPress={navigation.navigateCustomerProfile}
          focused={currentRoute === AppRouts.CustomerProfile}
        />
        <DrawerItemWrapper
          label='My Vehicles'
          onPress={navigation.navigateCustomerVehicles}
          focused={
            currentRoute === AppRouts.CustomerVehicles ||
            currentRoute === AppRouts.CustomerVehicleDetails
          }
        />
        <DrawerItemWrapper
          label='Open Service Request'
          onPress={navigation.navigateCustomerOpenServiceRequests}
          focused={currentRoute === AppRouts.CustomerOpenServiceRequests}
        />
        <DrawerItemWrapper
          label='Service History'
          onPress={navigation.navigateCustomerServiceHistory}
          focused={currentRoute === AppRouts.CustomerServiceHistory}
        />
      </View>

      {/* Divider */}
      <View style={styles.divider} />

      {/* Employee Section */}
      {user!.currentScope === UserScope.Employee && (
        <>
          <View style={styles.section}>
            <DrawerItemWrapper
              label='Employment Profile'
              onPress={navigation.navigateEmployeeProfile}
              focused={currentRoute === AppRouts.EmployeeProfile}
            />
            <DrawerItemWrapper
              label='Serviced Vehicles List'
              onPress={navigation.navigateEmployeeServicedVehicles}
              focused={currentRoute === AppRouts.EmployeeServicedVehicles}
            />
          </View>
          {/* Divider */}
          <View style={styles.divider} />
        </>
      )}

      {/* Company Owner Section */}
      {user!.currentScope === UserScope.CompanyOwner && (
        <>
          <View style={styles.section}>
            <DrawerItemWrapper
              label='Business Profile'
              onPress={navigation.navigateCompanyProfile}
              focused={currentRoute === AppRouts.CompanyProfile}
            />
            <DrawerItemWrapper
              label='Employee Data'
              onPress={navigation.navigateCompanyEmployees}
              focused={currentRoute === AppRouts.CompanyEmployees}
            />
            <DrawerItemWrapper
              label='Serviced Vehicles List'
              onPress={navigation.navigateCompanyServicedVehicles}
              focused={currentRoute === AppRouts.CompanyServicedVehicles}
            />
          </View>
          {/* Divider */}
          <View style={styles.divider} />
        </>
      )}

      {/* Settings Section */}
      <View style={styles.section}>
        {/* <DrawerItem label='Settings' onPress={switchProfile} /> */}
        <DrawerItem label='Logout' onPress={logout} />
      </View>
    </DrawerContentScrollView>
  )
}
