import axios from 'axios'
import config from '../config'
import { Platform } from 'react-native'

const baseApiUrl =
  Platform.OS === 'web' ? config.baseApiUrlWeb : config.baseApiUrlMobile

export const apiClient = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})
