import { apiClient } from '../../apiClient'
import { Vehicle } from './types'

export const updateVehicle = async (
  vehicleId: string,
  payload: Vehicle
): Promise<void> => {
  return apiClient.patch(`/vehicles/${vehicleId}`, {
    ...payload,
  })
}
