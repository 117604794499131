import { apiClient } from '../apiClient'

export type CompanySummary = {
  id: string
  name: string
  ownerId: string
  businessRegistrationNumber: string
  createdAt: Date
  updatedAt: Date
}

export const fetchCompanies = async (
  search?: string
): Promise<CompanySummary[]> => {
  const response = await apiClient.get('/companies/summary', {
    params: {
      ...(search && { search }), // Only include `search` if it's truthy
    },
  })

  return response.data.items || ([] as CompanySummary[])
}
