import React from 'react'
import { View, Text, Pressable } from 'react-native'
import { useNavigationService } from '../../../navigation/Service'
import { styles } from './styles'

export function HomePageScreen() {
  const navigation = useNavigationService()

  const onLoginPress = () => {
    navigation.navigateLogin()
  }

  return (
    <View style={styles.container}>
      <Text style={styles.header}>Welcome to the Mechanic Store!</Text>

      {/* Register Button */}
      <Pressable
        style={styles.registerButton}
        onPress={() => navigation.navigateRegister()}
      >
        <Text style={styles.buttonText}>Register</Text>
      </Pressable>

      {/* Dropdown Button & Items */}
      <View style={styles.dropdownWrapper}>
        {/* Dropdown Button */}
        <Pressable style={styles.dropdownButton} onPress={onLoginPress}>
          <Text style={styles.buttonText}>Login</Text>
        </Pressable>
      </View>
    </View>
  )
}
