import { Platform } from 'react-native'
// Import for mobile storage
import * as SecureStore from 'expo-secure-store'

const TOKEN_KEY = 'MECHANIC_SHOP_TOKEN'

// Web storage (for development)
const isWeb = Platform.OS === 'web'

export const saveToken = async (token: string) => {
  if (isWeb) {
    // Web (development) storage
    localStorage.setItem(TOKEN_KEY, token)
  } else {
    // Mobile platforms (production) storage
    await SecureStore.setItemAsync(TOKEN_KEY, token)
  }
}

export const getStoredToken = async () => {
  if (isWeb) {
    // Web (development) storage
    return localStorage.getItem(TOKEN_KEY)
  } else {
    // Mobile platforms (production) storage
    return await SecureStore.getItemAsync(TOKEN_KEY)
  }
}

export const removeStoredToken = async () => {
  if (isWeb) {
    // Web (development) storage
    localStorage.removeItem(TOKEN_KEY)
  } else {
    // Mobile platforms (production) storage
    await SecureStore.deleteItemAsync(TOKEN_KEY)
  }
}

// import * as SecureStore from 'expo-secure-store'
// const TOKEN_KEY = 'MECHANIC_SHOP_TOKEN'

// export const saveToken = async (token: string) => {
//   await SecureStore.setItemAsync(TOKEN_KEY, token)
// }

// export const getStoredToken = async () => {
//   return await SecureStore.getItemAsync(TOKEN_KEY)
// }

// export const removeStoredToken = async () => {
//   await SecureStore.deleteItemAsync(TOKEN_KEY)
// }
