import { apiClient } from '../../apiClient'
import { Employee } from './types'

export const fetchCompanyEmployees = async (
  companyId: string
): Promise<Employee[]> => {
  const response = await apiClient.get('/employees', {
    params: {
      companyId,
    },
  })

  return response.data?.items as Employee[]
}
