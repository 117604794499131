import React, { useEffect, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { fetchCurrentEmployee } from '../../../../api/adapters/employee/fetchCurrentEmployee'
import { styles } from './styles'
import {
  Employee,
  EmployeeStatus,
} from '../../../../api/adapters/employee/types'
import { CompanySummary } from '../../../../api/adapters/fetchCompanies'
import { fetchCompany } from '../../../../api/adapters/company/fetchCompany'

export function EmployeeProfileScreen() {
  const [employee, setEmployee] = useState<Employee | null>(null)
  const [company, setCompany] = useState<CompanySummary | null>(null)
  // Initial Load when screen loads
  useEffect(() => {
    initialLoad()
  }, [])

  const initialLoad = async () => {
    try {
      const data = await fetchCurrentEmployee()
      setEmployee(data)

      const company = await fetchCompany(data.companyId!)
      setCompany(company)
    } catch (err) {
      // TODO handle properly
      console.error('Something went wrong when fetching employee data', err)
    }
  }

  if (!employee) {
    return (
      <View style={styles.centered}>
        <Text style={styles.noProfileText}>No Employee Profile Available</Text>
      </View>
    )
  }

  if (!company) {
    return
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {employee.status === EmployeeStatus.Rejected && (
        <View style={styles.centered}>
          <Text style={styles.noProfileText}>
            Unfortunately your request to join{' '}
            <Text style={styles.value}>{company.name}</Text> has been rejected.
          </Text>
        </View>
      )}
      {employee.status === EmployeeStatus.Pending && (
        <View style={styles.centered}>
          <Text style={styles.noProfileText}>
            Your request to join:
            <View style={styles.section}>
              <Text style={styles.value}>{company.name}</Text>
            </View>
            , is currently in review. Thank you for the patience.
          </Text>
        </View>
      )}
      {employee.status === EmployeeStatus.Active && (
        <>
          <View style={styles.section}>
            <Text style={styles.label}>
              Company Business Registration Number
            </Text>
            <Text style={styles.value}>
              {company.businessRegistrationNumber}
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.label}>Company Name</Text>
            <Text style={styles.value}>{company.name}</Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.label}>Company in business from:</Text>
            <Text style={styles.value}>
              {new Date(company.createdAt).toLocaleDateString()}
            </Text>
          </View>
        </>
      )}
    </ScrollView>
  )
}
