import { apiClient } from '../apiClient'

export type AuthenticateResponse = {
  token: string
}

export const authenticate = async (
  email: string,
  password: string
): Promise<AuthenticateResponse> => {
  const response = await apiClient.post('/auth/login', {
    email,
    password,
  })

  return response.data as AuthenticateResponse
}
