import React from 'react'
import { View, Text } from 'react-native'
import commonStyles from '../../../../common/styles/commonStyles'

export function EmployeeServicedVehiclesScreen() {
  return (
    <View style={commonStyles.container}>
      <Text style={commonStyles.text}>
        Welcome to Employee Serviced Vehicles Screen
      </Text>
    </View>
  )
}
