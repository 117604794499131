import React from 'react'
import { View, Text, TextInput } from 'react-native'
import { Controller } from 'react-hook-form'
import { styles } from './styles'
import { TextInputType } from './type'

// TODO Extend functionality to make more general
export const TextInputField = ({
  control,
  name,
  label,
  placeholder,
  defaultValue,
  errorMessage,
  editable = true,
}: TextInputType) => (
  <View style={styles.inputContainer}>
    <Text style={styles.label}>{label}</Text>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field: { onChange, onBlur, value } }) =>
        editable ? (
          <TextInput
            style={styles.input}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholder={placeholder}
          />
        ) : (
          <Text style={styles.valueLabel}>{value}</Text> // Render as label if not editable
        )
      }
    />
    {errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
  </View>
)
