import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { AppRouts, RootStackParamList } from '../../../types'
import { EmployeeProfileScreen } from '../../../../screens/private/Employee/EmployeeProfile'
import { EmployeeServicedVehiclesScreen } from '../../../../screens/private/Employee/EmployeeServicedVehicles'

const Drawer = createDrawerNavigator<RootStackParamList>()

export function LoadEmployeeRoutes() {
  return (
    <>
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Employment Profile',
        }}
        name={AppRouts.EmployeeProfile}
        component={EmployeeProfileScreen}
      />
      <Drawer.Screen
        options={{
          headerShown: true,
          title: 'Employee Serviced Vehicles',
        }}
        name={AppRouts.EmployeeServicedVehicles}
        component={EmployeeServicedVehiclesScreen}
      />
    </>
  )
}
