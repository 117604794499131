import { apiClient } from '../../apiClient'
import { Vehicle } from './types'

export const fetchVehicles = async (customerId: string): Promise<Vehicle[]> => {
  const response = await apiClient.get('/vehicles', {
    params: {
      customerId,
    },
  })

  return response.data?.items as Vehicle[]
}
