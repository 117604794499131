import React, { createContext, useState, useContext, ReactNode } from 'react'

const SpinnerContext = createContext<{
  loading: boolean
  startLoader: () => void
  stopLoader: () => void
}>({
  loading: false,
  startLoader: () => {},
  stopLoader: () => {},
})

export const SpinnerProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false)

  const startLoader = () => {
    // console.info('[SpinnerProvider]: Start loader')
    setLoading(true)
  }

  const stopLoader = () => {
    // console.info('[SpinnerProvider]: Stop loader')
    setLoading(false)
  }

  return (
    <SpinnerContext.Provider value={{ loading, startLoader, stopLoader }}>
      {children}
    </SpinnerContext.Provider>
  )
}

export const useSpinner = () => {
  const context = useContext(SpinnerContext)
  if (!context) {
    throw new Error('useSpinner must be used within SpinnerProvider')
  }
  return context
}
