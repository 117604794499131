import * as yup from 'yup'

// Define the form schema using Yup
export const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(/[A-Z]/, 'At least one uppercase letter is required')
    .matches(/[a-z]/, 'At least one lowercase letter is required')
    .matches(/\d/, 'At least one digit is required')
    .matches(
      /[@$!%*#?&]/,
      'At least one special character is required: @$!%*#?&'
    )
    .min(8, 'Password must be at least 8 characters long'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Please confirm your password'),
  phone: yup
    .string()
    .matches(
      /^\+[1-9]{1}[0-9]{3,14}$/,
      'Phone number must be International format'
    )
    .required('Phone number is required'),
})
