export const linking = {
  prefixes: [
    'mechanicshopapp://',
    'http://usmechanicservices.com',
    'http://localhost:8081',
  ],
  config: {
    screens: {
      // Public Routes
      Home: 'Home',
      Register: 'Register',
      AccountActivation: 'AccountActivation',
      Login: 'Login',
      NotFound: 'NotFound',

      // Private Roues
      Drawer: 'Drawer',
      CreateProfile: 'CreateProfile',

      // Main
      CustomerProfile: 'CustomerProfile',
      CustomerVehicles: 'CustomerVehicles',
      CustomerVehicleDetails: 'CustomerVehicleDetails',
      CustomerOpenServiceRequests: 'CustomerOpenServiceRequests',
      CustomerServiceHistory: 'CustomerServiceHistory',
      Settings: 'Settings',

      // Company
      CompanyProfile: 'CompanyProfile',
      CompanyEmployees: 'CompanyEmployees',
      CompanyServicedVehicles: 'CompanyServicedVehicles',

      // Employee
      EmployeeProfile: 'EmployeeProfile',
      EmployeeServicedVehicles: 'EmployeeServicedVehicles',

      // Vehicles: {
      //   path: 'vehicles',
      //   screens: {
      //     List: 'list',
      //     Detail: 'detail/:id',
      //   },
      // },
    },
  },
}
