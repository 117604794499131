import { StyleSheet } from 'react-native'

// Styling for the component
export const styles = StyleSheet.create({
  inputContainer: {
    width: '100%',
    marginBottom: 15,
  },
  label: {
    fontSize: 14,
    color: '#555', // Light color for label
    marginBottom: 4, // Small padding between label and input
  },
  input: {
    width: '100%',
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
  },
  errorText: {
    color: 'red',
    marginTop: 5,
  },
  valueLabel: {
    color: 'gray',
    paddingVertical: 10,
    // Other styles to indicate it's read-only
  },
})
