import React, { useEffect, useState } from 'react'
import { View, Text, ScrollView, TouchableOpacity } from 'react-native'
import { styles } from './styles'
import { useAuth } from '../../../../contexts/AuthContext'
import { fetchCompanyEmployees } from '../../../../api/adapters/employee/fetchCompanyEmployees'
import { MaterialIcons } from '@expo/vector-icons'
import {
  Employee,
  EmployeeStatus,
} from '../../../../api/adapters/employee/types'
import { updateEmployeeStatus } from '../../../../api/adapters/employee/updateEmployeeStatus'
import { showAlert } from '../../../../common/utils/alert'
import { AxiosError } from 'axios'

export function CompanyEmployeesScreen() {
  const { user } = useAuth()
  const [employees, setEmployees] = useState<Employee[]>([])

  //Fetch Employee Profile when screen loads
  useEffect(() => {
    loadEmployees()
  }, [])

  const handleStatusUpdate = async (id: string, status: EmployeeStatus) => {
    try {
      await updateEmployeeStatus(id, status)
      showAlert('Success', 'Status successfully Updated!')
      loadEmployees()
    } catch (error) {
      const errorMessage = (
        (error as AxiosError).response!.data as { error: string }
      ).error
      console.error(error)
      showAlert('Error', errorMessage)
    }
  }

  const loadEmployees = async () => {
    try {
      const data = await fetchCompanyEmployees(
        user!.profile!.companyProfile!.companyId!
      )
      console.log('[fetchCompanyEmployees]: ', data)

      setEmployees(data)
    } catch (err) {
      // TODO handle properly
      console.error('Something went wrong when fetching employee data', err)
    }
  }

  if (!employees.length) {
    return (
      <View style={styles.centered}>
        <Text style={styles.noProfileText}>No Employees Available</Text>
      </View>
    )
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {employees.map((employee: Employee) => (
        <View style={styles.row} key={employee.id}>
          <Text style={styles.column}>{employee.profile!.email}</Text>
          <Text style={styles.column}>{employee.status}</Text>
          <View style={styles.actionColumn}>
            {employee.status === EmployeeStatus.Pending ? (
              <>
                <TouchableOpacity
                  onPress={() =>
                    handleStatusUpdate(employee.id, EmployeeStatus.Active)
                  }
                  style={styles.iconButton}
                >
                  <MaterialIcons name='check-circle' size={24} color='green' />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() =>
                    handleStatusUpdate(employee.id, EmployeeStatus.Rejected)
                  }
                  style={styles.iconButton}
                >
                  <MaterialIcons name='cancel' size={24} color='red' />
                </TouchableOpacity>
              </>
            ) : (
              <View style={styles.placeholder} />
            )}
          </View>
        </View>
      ))}
    </ScrollView>
  )
}
