import { apiClient } from '../apiClient'

export type RegisterUserResponse = {
  id: string
}

export const registerUser = async (
  email: string,
  password: string,
  phone: string
): Promise<RegisterUserResponse> => {
  const response = await apiClient.post('/auth/register', {
    email,
    password,
    phone,
  })

  return response.data as RegisterUserResponse
}
