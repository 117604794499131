import { apiClient } from '../apiClient'

export type ActivateAccountResponse = {
  success: boolean
}

export const activateAccount = async (
  userId: string,
  code: string
): Promise<ActivateAccountResponse> => {
  const response = await apiClient.post(`/auth/${userId}/activation`, {
    code,
  })

  return response.data as ActivateAccountResponse
}
