import { Profile } from '../fetchCurrentUser'

export enum EmployeeStatus {
  Pending = 'Pending',
  Active = 'Active',
  Rejected = 'Rejected',
}

export type Employee = {
  id: string
  userId: string
  companyId?: string
  profile?: Pick<Profile, 'email' | 'firstName' | 'lastName' | 'phone'>
  status: EmployeeStatus
  data?: unknown
  createdAt?: Date
}
