import React, { useEffect, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'

import { useAuth } from '../../../../contexts/AuthContext'
import { styles } from './styles'
import { Profile } from '../../../../api/adapters/fetchCurrentUser'

export function CustomerProfileScreen() {
  const { user } = useAuth()
  const [profile, setProfile] = useState<Profile>()

  useEffect(() => {
    setProfile(user?.profile)
  }, [user])

  if (!profile) {
    return (
      <View style={styles.centered}>
        <Text style={styles.noProfileText}>No Profile Available</Text>
      </View>
    )
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.section}>
        <Text style={styles.label}>First Name</Text>
        <Text style={styles.value}>{profile.firstName}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.label}>Last Name</Text>
        <Text style={styles.value}>{profile.lastName}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.label}>Email</Text>
        <Text style={styles.value}>{profile.email}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.label}>Phone</Text>
        <Text style={styles.value}>{profile.phone}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.label}>Date of Birth</Text>
        <Text style={styles.value}>
          {new Date(profile.dateOfBirth).toLocaleDateString()}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.label}>Address</Text>
        <Text style={styles.value}>{profile.address}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.label}>Account Created At</Text>
        <Text style={styles.value}>
          {new Date(profile.createdAt).toLocaleDateString()}
        </Text>
      </View>
    </ScrollView>
  )
}
